<template>
  <b-card-code>
    <b-button
      @click="addAssetType()"
      type="button"
      variant="primary"
      class="my-1"
      size="sm"
    >
      Add New
    </b-button>
    <b-table
      responsive
      id="atTable"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
      hover
      style="min-height: 200px;"
    >
      <template #cell(index)="data">
        {{ data.index + 1 + perPage * (currentPage - 1) }}
      </template>
      <template #cell(asset_type_name)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            variant="primary"
            size="sm"
            :text="data.item.asset_type_name.charAt(0)"
          />
          <span class="d-flex" style="margin-left: 5px">{{
            data.item.asset_type_name
          }}</span>
        </div>
      </template>
      <template #cell(actions)="data">
        <!-- <button @click="editAssetType(data.item.asset_type_id)" type="button" class="btn btn-primary btn-sm mr-1" style="position: relative;"> Edit</button>
        <button @click="deleteAssetType(data.item.asset_type_id)" type="button" class="btn btn-danger btn-sm mr-1" style="position: relative;"> Delete </button>
         -->
        <b-dropdown
          size="sm"
          class="ml-1"
          variant="outline-primary"
          text="Actions"
        >
          <b-dropdown-item @click="editAssetType(data.item.asset_type_id)"
            >Edit</b-dropdown-item
          >
          <b-dropdown-divider />
          <b-dropdown-item
            @click="
              gotoDelete(data.item.asset_type_id, data.item.asset_type_name)
            "
            >Delete</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="atTable"
    />
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete {{ asset_type_name }} ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteAssetType()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BButton,
  BAvatar,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButton,
    BAvatar,
  },
  data() {
    return {
      // Call orgaization API and return in this format
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        { key: "index", label: "#" },
        // { key: "asset_type_id", label: "#" },
        { key: "asset_type_name", label: "Name" },
        { key: "asset_type_description", label: "Description" },
        { key: "actions", label: "Actions" },
      ],
      asset_type_id: "",
      asset_type_name: "",
      openDeleteModal: false,
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/asset-type",
      };
      this.$http(options).then((res) => {
        console.log(res.data.results);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data;
        this.rows = res.data.length;
      });
    },
    addAssetType() {
      this.$router.push({ name: "Add AssetTypes" });
    },
    editAssetType(id) {
      this.$router.push({ name: "Edit AssetTypes", params: { id: id } });
    },
    gotoDelete(asset_type_id, asset_type_name) {
      this.openDeleteModal = true;
      this.asset_type_id = asset_type_id;
      this.asset_type_name = asset_type_name;
    },
    deleteAssetType() {
      // this.asset_type_name = asset_type_name;
      // if (
      //   confirm("Do you really want to delete " + this.asset_type_name + "?")
      // ) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/asset-type/" +
          this.asset_type_id +
          "/",
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.openDeleteModal = false;
        this.load();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
      // }
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 150px;
  overflow-y: auto;
}
</style>
